<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableNew'
import { getAction } from '@/command/netTool'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
import moment from 'moment'
export default {
  mixins: [hotelMixin, eatDrinkMixin, farmingExperienceMixin,scenicAreasTicketsMixin],
  methods: {
    getTimePickerKeys() {
      const { timeStr = '', selectType = '' } = this.$route.query
      const split = timeStr.split('~')
      switch (selectType) {
        case '0':
          return { dateGe: timeStr, dateLe: timeStr }
        case '1':
          return { dateGe: split[0], dateLe: split[1] }
        case '2':
          return { dateGe: split[0], dateLe: split[1] }
        default:
          return {}
      }
    },
    getHeader() {
      const { timeStr } = this.$route.query
      return [
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '全部',
              value: '',
            },
            {
              name: '待使用',
              value: 1,
            },
            {
              name: '已完成',
              value: 5,
            },
          ],
        },
        {
          type: timeStr ? 'text' : 'rangePicker',
          name: '时间',
          key: 'timeStr',
          value: timeStr ? timeStr : null,
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '出售商品',
          dataIndex: 'productName',
        },
        // {
        //   title: '数量',
        //   dataIndex: 'productQuantity',
        //   customRender: (_, records) => {
        //     const num = records.orderItemList.reduce((a, b) => b.productQuantity + a, 0)
        //     return num
        //   },
        // },
        {
          title: '入住人',
          dataIndex: 'concatName',
        },
        {
          title: '电话',
          dataIndex: 'concatPhone',
        },
        {
          title: '间数',
          dataIndex: 'productQuantity',
        },
        {
          dataIndex: 'salesJsAmount',
          title: '结算金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{(records.salesJsAmount || 0).toFixed(2)}</span>
              </div>
            )
          },
        },
        {
          title: '实付金额',
          dataIndex: 'payAmount',
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: (text, records) => {
            let newText = text
            if (records.refundAmount) {
              newText = newText + `(退款:${records.refundAmount})`
            }
            return newText
          },
        },
        {
          title: '入住时间',
          dataIndex: 'roomInTime',
        },
        {
          title: '离店时间',
          dataIndex: 'roomOutTime',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '核销时间',
          dataIndex: 'usedTime',
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data) => {
            if (data == 102) return '待入住'
            const state = ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][data]
            return <div>{state}</div>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: '详情',
                onClick: () => {
                  // 订单来源：1农家特产 2 农家乐 4农事体验 12 景点门票 13 酒店民宿
                  const fromType = records.fromType
                  switch (fromType) {
                    case '1':
                      break
                    case '2':
                      this.onRowSelectEatDrink(records.id)
                      break
                    case '4':
                      this.onRowSelectFarmingExperience(records.id)
                      break
                    case '12':
                      this.onRowSelectScenicAreasTickets(records)
                      break
                    case '13':
                    case '14':
                      this.onRowSelectHotel(records)
                      break
                  }
                },
              },
            ]
          },
        },
      ]
    },
    onHeaderCallBack(data) {
      const { timeStr } = this.$route.query
      if (timeStr) {
        return {}
      } else if (data.timeStr) {
        const dateGe = moment(data.timeStr[0]).format('YYYY-MM-DD')
        const dateLe = moment(data.timeStr[1]).format('YYYY-MM-DD')
        return { dateGe, dateLe, dateTypeQuery: '0' }
      } else {
        return {}
      }
    },
  },
  render() {
    const { dateLe, dateGe, dateTypeQuery, businessId } = this.$route.query
    return (
      <TemplateHeaderTableNew
        url={`/reportForm/getOrderPage`}
        pageParams={{ businessId, ...this.getTimePickerKeys() }}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        onHeaderCallBack={this.onHeaderCallBack}
      />
    )
  },
}
</script>
