import api from '@/command/api'
import orderConfig from '@/orderConfig'
import { getAction, getParams, downloadAction } from '@/command/netTool'


export const eatDrinkMixin = {
    methods: {
        // 获取选中订单详情
        getDetailDataEatDrink(id) {
            return new Promise((resolve, reject) => {
                getAction(`/api/agr/order/detail?id=${id}`)
                    .then((result) => {
                        resolve(result.data)
                    })
                    .catch((e) => reject && reject(e))
            })
        },
        onRowSelectEatDrink(orderId, orderItemId) {
            const ext = [
                // 表格
                orderConfig.table,
                // // 退款信息
                // orderConfig.retuenInfo,
                // 可选择表格
                orderConfig.tableTicketsSelect,
                // 基本信息
                orderConfig.base,
                // // 优惠券
                // orderConfig.coupons,
                // 记录
                orderConfig.record,
            ]

            this.getDetailDataEatDrink(orderId, orderItemId).then((e) => {
                orderConfig.open({
                    data: e,
                    params: { data: e, customOrderId: orderId, customOrderItemId: orderItemId },
                    executeFun: ext,
                    onHidden: () => {
                        this.getInit()
                    },
                    foot: ({ close, submit }) => {
                        return [
                            {
                                name: '取消',
                                onClick: close,
                            },
                        ]
                    },
                })
            })
        },
    }
}
